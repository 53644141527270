
<template>
  <div class="navTop">
    <div class="navTop-left">
      <i class="el-icon-s-unfold" v-if="isCollapse == true" style="font-size:24px;color: #5C5C5C;"
        @click="handleCollapse(false)"></i>
      <i class="el-icon-s-fold" v-if="isCollapse == false" style="font-size:24px;color: #5C5C5C;"
        @click="handleCollapse(true)"></i>
      <p class="name">团火后台管理系统</p>
    </div>
    <div class="navTop-right">
      <img :src="userInfo.avatar" alt="">
      <span>{{ userInfo.username }}</span>
      <el-dropdown>
        <span class="el-dropdown-link">
          <i class="el-icon-caret-bottom el-icon--right" style="font-size: 18px;"></i>
        </span>
        <el-dropdown-menu slot="dropdown" class="custom-dropdown-menu">
          <el-dropdown-item>
            <span @click="dialogVisible = true">修改密码</span>
          </el-dropdown-item>
          <el-dropdown-item>
            <span @click="signOutFn">退出</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 修改密码 -->
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%" append-to-body>
      <el-form ref="form" :model="passWordForm" label-width="100px">
        <el-form-item label="旧密码">
          <el-input v-model="passWordForm.password" show-password placeholder="请输入旧密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码">
          <el-input v-model="passWordForm.new_password" show-password placeholder="请输入新密码"></el-input>
        </el-form-item>
        <el-form-item label="重复新密码">
          <el-input v-model="passWordForm.repeat_password" show-password placeholder="请重复输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="warning" @click="passWordFn">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      userInfo: {},
      passWordForm: {},
      dialogVisible: false
    }
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem('member'))
  },
  methods: {
    handleCollapse(val) {
      this.isCollapse = val
      this.$emit('event-collapse', val);
    },
    // 修改密码
    passWordFn() {
      this.$http.post(`erp/v1/home/password`, this.passWordForm).then((res) => {
        const { code, data, msg } = res.data
        this.loading = false
        if (code == 200) {
          this.$message.success(msg);
          this.dialogVisible = false
        } else {
          this.$message.error(msg);
        }
      });
    },
    // 退出
    signOutFn() {
      this.$confirm('确定退出当前系统吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        confirmButtonClass: 'el-button--warning',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$router.push("/login");
        sessionStorage.clear()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        });
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.navTop {
  position: relative;
  height: 60px;
  background: #fff;
  padding: 0 20px 0 10px;
  z-index: 1;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;

  .navTop-left {
    display: flex;
    align-items: center;
    color: #24242C;
    font-size: 20px;

    .name {
      margin-left: 10px;
    }
  }

  .navTop-right {
    display: flex;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 8px;
    }
  }
}

// dropdown
.custom-dropdown-menu .el-dropdown-menu__item:hover {
  background-color: #FCE8E5;
  color: #FC7414;
}
</style>