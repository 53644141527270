<template>
	<div class="navMenu">
		<template v-for="navMenu in navMenus">
			<!-- 最后一级菜单 -->
			<el-menu-item v-if="!navMenu.children" :key="navMenu.id" :data="navMenu" :index="navMenu.router"
				@click="storageMenu(navMenu)">
				<template slot="title">
					<i :class="navMenu.icon"></i>
					<span style="padding-right: 10px;">{{ navMenu.name }}</span>
					<el-badge :value="customerApply" v-if="navMenu.id == 53 && customerApply > 0"></el-badge>
				</template>

			</el-menu-item>
			<!-- 此菜单下还有子菜单 -->
			<el-submenu v-if="navMenu.children" :key="navMenu.id" :data="navMenu" :index="navMenu.id + ''">
				<template slot="title">
					<i :class="navMenu.icon"></i>
					<span style="padding-right: 10px;">{{ navMenu.name }}</span>
					<el-badge :value="customerApply" v-if="navMenu.id == 5 && customerApply > 0"></el-badge>
					<el-badge value="Beta" v-if="navMenu.id == 1010"></el-badge>
				</template>
				<!-- 递归 -->
				<NavMenu :navMenus="navMenu.children"></NavMenu>
			</el-submenu>
		</template>
	</div>
</template>

<script>
	export default {
		name: "NavMenu",
		props: ["navMenus"],
		data() {
			return {
				customerApply: 0
			};
		},
		mounted() {
			setTimeout(() => {
				this.customerApply = window.sessionStorage.getItem('customerApply')
			}, 1000);
		},
		methods: {
			// 存储菜单
			storageMenu(navMenu) {
				sessionStorage.setItem('activeMenu', navMenu.router);
			},
		}
	};
</script>

<style></style>