<template>
	<div class="main-page">
		<div class="main-menu" :style="{ width: isCollapse ? '64px!important' : '250px' }">
			<div class="mainMenuHeader">
				<img class="logo" src="../assets/img/logo.png" alt="">
				<span class="mainMenuHeader-tips" v-if="isCollapse == false">团火ERP</span>
			</div>
			<el-scrollbar style="height:90%">
				<el-menu unique-opened background-color="#fff" text-color="#3C3C3C" active-text-color="#ff720e"
					:default-active="activeIndex" router :collapse="isCollapse">
					<navMenu-left :navMenus="menuData"></navMenu-left>
				</el-menu>
			</el-scrollbar>
		</div>
		<div class="main-right" :style="{ 'margin-left': isCollapse ? '64px!important' : '250px' }">
			<navMenu-top @menuChildren="getMenuChildren" @event-collapse="isCollapse = $event"></navMenu-top>
			<div class="main-content">
				<router-view></router-view>
			</div>
		</div>
	</div>
</template>

<script>
import NavMenuLeft from "../components/nav/left.vue";
import NavMenuTop from "../components/nav/top.vue";
export default {
	components: {
		'navMenu-left': NavMenuLeft,
		'navMenu-top': NavMenuTop,
	},
	data() {
		return {
			activeIndex: "dataAnalysis",
			isCollapse: false,
			menuData: [{
				id: 8,
				name: "数据罗盘",
				icon: "el-icon-data-line",
				router: 'dataAnalysis'
			},
			{
				id: 1,
				name: "仓库管理",
				icon: "el-icon-box",
				children: [
					// {
					//   id: 10,
					//   name: "仓库分类",
					//   router: 'warehouseClassify'
					// },
					{
						id: 11,
						name: "仓库资料",
						router: 'warehouseData'
					}
				]
			},
			{
				id: 2,
				name: "商品管理",
				icon: "el-icon-goods",
				children: [{
					id: 20,
					name: "商品分类",
					router: 'commodityClassify'
				},
				{
					id: 21,
					name: "商品资料",
					router: 'commodityData'
				},
				{
					id: 22,
					name: "潮汐库存",
					router: 'stock'
				}
				]
			},
			{
				id: 3,
				name: "供应商",
				icon: "el-icon-office-building",
				children: [{
					id: 30,
					name: "供应商管理",
					router: 'supplierManage'
				}]
			},
			{
				id: 4,
				name: "路线管理",
				icon: "el-icon-thumb",
				children: [{
					id: 40,
					name: "配送路线",
					router: 'deliveryRoute'
				},
					// {
					//   id: 41,
					//   name: "司机管理",
					//   router: 'driverManagement'
					// }
				]
			},
			{
				id: 5,
				name: "客户管理",
				icon: "el-icon-connection",
				children: [{
					id: 50,
					name: "客户分组",
					router: 'customerGroup'
				},
				{
					id: 51,
					name: "客户资料",
					router: 'customerData'
				},
				{
					id: 52,
					name: "黑名单",
					router: 'blacklist'
				},
				{
					id: 53,
					name: "合作申请",
					router: 'cooperationApply'
				}
				]
			},
			{
				id: 6,
				name: "销售管理",
				icon: "el-icon-document-checked",
				children: [{
					id: 60,
					name: "销售开单",
					router: 'salesBilling'
				},
				{
					id: 61,
					name: "订单列表",
					router: 'orderList'
				},
				{
					id: 62,
					name: "订单列表（信用付）",
					router: 'orderListXyf'
				},
				{
					id: 63,
					name: "订单列表（地址）",
					router: 'orderListAddress'
				},
				{
					id: 64,
					name: "售后管理",
					router: 'afterSales'
				},
				{
					id: 65,
					name: "销售汇总",
					router: 'salesSummary'
				},
				{
					id: 66,
					name: "截单时间",
					router: 'cutTimeSet'
				}
				]
			},
			{
				id: 7,
				name: "配送管理",
				icon: "el-icon-position",
				children: [{
					id: 70,
					name: "配送单",
					router: 'preDeliveryNote'
				},
				{
					id: 71,
					name: "配送单（地址）",
					router: 'preDeliveryNoteAddress'
				},
				{
					id: 72,
					name: "配送单（商品）",
					router: 'preDeliveryNoteGoods'
				},
				{
					id: 73,
					name: "配送汇总",
					router: 'preDeliverySummary'
				},
				{
					id: 74,
					name: "配送汇总（采购）",
					router: 'preDeliverySummaryDay'
				},
				{
					id: 75,
					name: "配送费预查",
					router: 'preDeliveryCheck'
				},
				{
					id: 76,
					name: "休息日设置",
					router: 'restDaySet'
				}
				]
			},
			{
				id: 9,
				name: "采购入库",
				icon: "el-icon-takeaway-box",
				children: [{
					id: 90,
					name: "入库管理",
					router: 'warehouseManagement'
				},
				{
					id: 91,
					name: "入库开单",
					router: 'warehouseReceipt'
				},
				{
					id: 92,
					name: "入库汇总",
					router: 'warehouseSummary'
				}
				]
			},
			{
				id: 1010,
				name: "财务管理",
				icon: "el-icon-copy-document",
				children: [{
					id: 1011,
					name: "日收入汇总",
					router: 'dayIncomeSummary'
				},
				{
					id: 1012,
					name: "月收入汇总",
					router: 'monthIncomeSummary'
				}
				]
			}
			]
		};
	},
	mounted() {
		this.activeIndex = sessionStorage.getItem('activeMenu')
	},
	methods: {
		getMenuChildren(data) {
			this.menuData = data
		},
	}
};
</script>

<style lang="scss" scoped>
.main-page {
	width: 100%;
	height: 100%;

	.main-menu {
		transition: width 0.28s;
		background-color: #fff;
		height: 100%;
		position: fixed;
		font-size: 0px;
		top: 0;
		bottom: 0;
		left: 0;
		z-index: 1001;
		overflow: hidden;

		.mainMenuHeader {
			position: relative;
			height: 60px;
			background: #fff;
			display: flex;
			justify-content: center;
			align-items: center;
			border-right: 1px solid #F5F5F5;
			border-bottom: 1px solid #F5F5F5;

			.logo {
				width: 30px;
				height: 30px;
				vertical-align: middle;
				margin-right: 6px;
			}

			.mainMenuHeader-tips {
				font-size: 24px;
				font-weight: bold;
				color: #2C2C2C;
			}
		}

		.el-menu {
			border-right: none;
		}

		/*隐藏文字*/
		/deep/.el-menu--collapse .el-submenu__title span {
			display: none;
		}

		/*隐藏 > */
		/deep/.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
			display: none;
		}
	}

	.main-right {
		min-height: 100%;
		transition: margin-left 0.28s;
		position: relative;
		background: #F0F1F4;

		.main-content {
			padding: 15px;
		}
	}
}

/deep/.el-scrollbar__wrap {
	overflow-x: hidden;
}
</style>